module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Courier Prime","Open Sans:300,400,500,800"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"Nry28FdyrJcfdf6PTREnpC6hYee4N1Mb","devKey":"Nry28FdyrJcfdf6PTREnpC6hYee4N1Mb","trackPage":true,"manualLoad":true,"delayLoad":false,"delayLoadTime":500},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
